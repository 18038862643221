import { Image, Text, Flex, Heading, Button } from "@adobe/react-spectrum";
import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";

function SubFeatures() {
  const navigate = useNavigate();

  return (
    <>
      <Flex
        marginY="size-250"
        direction={{ base: "column-reverse", S: "row" }}
        gap="size-200"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex direction="column" gap="size-250" maxWidth={{ base: "100%", S: "45%" }}>
          <Heading level={2} UNSAFE_style={{ textDecoration: "underline wavy", fontSize: "2rem" }} marginY="size-0">
            État d&apos;esprit
          </Heading>
          <Text>
            Tramway est fait pour les &quot;Roots&quot;. Si vous cherchez une connexion authentique, vous êtes au bon
            endroit. Ce n&apos;est pas un simple site de rencontre pour adultes de tous horizons, mais un tiers-lien où
            la nature, la durabilité et l&apos;authenticité sont célébrées. Ici, on crée des souvenirs qui durent, dans
            un esprit de simplicité et de respect.
          </Text>
          <Button
            alignSelf={{ base: "end", S: "start" }}
            variant="primary"
            width="size-1200"
            onPress={() => navigate(PUBLIC_ROUTES.manifesto)}
          ><Text>Manifesto</Text>
          </Button>
        </Flex>
        <Image
          maxWidth={{ base: "80%", S: "50%", L: "40%" }}
          alt="Un couple amoureux observe les étoiles en pleine nature, partageant un moment romantique lors d'un pique-nique sous le ciel étoilé."
          src="etat_esprit.png"
        />
      </Flex>

      <Flex
        marginY="size-100"
        direction={{ base: "column", S: "row" }}
        gap="size-200"
        justifyContent="space-between"
        alignItems="center"
      >
        <Image
          maxWidth={{ base: "90%", S: "50%", L: "50%" }}
          alt="Un couple amoureux handi-valide savoure un café matinal en pleine forêt, avec une tente installée en arrière-plan et des étoiles à peine visibles, partageant un moment romantique."
          src="handi_pmr.png"
        />
        <Flex direction="column" gap="size-250" maxWidth={{ base: "100%", S: "45%" }}>
          <Heading level={2} UNSAFE_style={{ textDecoration: "underline wavy", fontSize: "2rem" }} marginY="size-0">
            Handicap
          </Heading>
          <Heading level={3} marginY="size-0">
            Un site de rencontre ouvert au handicap
          </Heading>
          <Text>
            Nous croyons que l’amour handi-valide est non seulement possible, mais aussi enrichissant. Tramway est une
            plateforme engagée, pensée pour les célibataires en quête d’authenticité et d’accessibilité. Ici, chacun
            peut se connecter, partager des moments uniques et construire des souvenirs durables, quelle que soit son
            histoire.
          </Text>
          <Button
            alignSelf={{ base: "end", S: "start" }}
            variant="primary"
            width="size-1600"
            onPress={() => navigate(PUBLIC_ROUTES.accessibilite)}
          ><Text>Accessibilité</Text>
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

export default SubFeatures;
